import './index.less';
const AdContainer = () => {
  return (
    <div className="right-marketing-container">
      <a
        href="https://www.cvent.com/en/resource/hospitality/special-event-venues-offer-individualized-experiences?utm_campaign=MTM--eBook--unique_venues&utm_medium=internal_web&utm_source=login_ads&utm_prod=CED&utm_reg=NA&utm_aud=ALL&utm_eng=ALL&utm_ver=UV&utm_per=NonP&utm_ptype=DG-TOFU&utm_bus=HC"
        aria-label="Click to download a free copy of 'Unique Event Spaces: The Latest Trend in Venue Selection'."
        target="_blank"
      >
        <img
          className="hs-cta-img"
          src="../assets/images/ad.png"
          alt="Click to download a free copy of 'Unique Event Spaces: The Latest Trend in Venue Selection'."
        />
      </a>
    </div>
  );
};
export default AdContainer;
