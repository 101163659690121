export const getQueryString = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const deconstructInvitationCode = (invitationCode) => {
  const [id, encodedEmail] = invitationCode.split(':');
  if (!id || !encodedEmail) {
    return {
      id: '',
      email: '',
    };
  }
  return {
    id,
    email: atob(encodedEmail),
  };
};
