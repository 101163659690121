import {useContext, useEffect} from 'react';
import {globalUIContext} from '../../utils/global-ui-helpers';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import SignupForm from '../../components/signup-form';

const SignupPage = () => {
  const {
    isMobile,
    selectedPlanType,
    email,
    setGlobalEmail,
    isCollaborator,
    setBody,
  } = useContext(globalUIContext);

  return (
    <>
      <SignupForm
        planType={selectedPlanType}
        globalEmail={email}
        setGlobalEmail={setGlobalEmail}
        isCollaborator={isCollaborator}
        setBody={setBody}
      />
      {isMobile ? (
        <PlanSummaryMobile planType={selectedPlanType} />
      ) : (
        <PlanSummary planType={selectedPlanType} />
      )}
    </>
  );
};

export default SignupPage;
